<template>
	<el-container class="home flex" style="flex-direction: column;">
		<el-header class="menuTop flex">
			<div class="logo flex jc-center ai-center mr-30" @click="openIndex()">
				<div class="img mx-5 pt-5"><img src="../../assets/osImg/logo.png" alt="" style="height: 28px;width: 28px;" /></div>
				<div class="title">棒线材设备智能运维系统</div>
			</div>

			<div class="menu flex-1">
				<el-menu mode="horizontal" style="background-color: #141414;background-image: linear-gradient(rgba(38, 47, 57, 1), rgba(78, 92, 107, 1));" class="topMenu">
					<el-menu-item
						ref="topMenu"
						style="line-height: 48px; padding: 0 12px;"
						v-for="(item, i) in topMenu"
						:key="item.url"
						:index="item.url ? item.url : item.name"
						@click="selectTopMenu(item, i)"
					>
						{{ item.name }}
					</el-menu-item>
				</el-menu>
			</div>

			<div class="message flex ai-center jc-between">
				<el-select v-model="userObj.dept" placeholder="请选择" disabled size="mini">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-popover placement="bottom" width="400" trigger="hover">
					<div>
						<div class="flex jc-between userInfo">
							<div>账户信息</div>
							<el-button style="padding:0 20px" type="text" @click="accSetting">账户设置</el-button>
						</div>
						<div>
							<div class="textInfo">
								<span class="info">所在部门：</span>
								<span>{{ userObj.dept }}</span>
							</div>
							<div class="textInfo">
								<span class="info">本次登录：</span>
								<span>{{ userObj.loginTimeCurrent }}</span>
							</div>
							<div class="textInfo">
								<span class="info">登录地区：</span>
								<span>{{ userObj.loginArea }}({{ userObj.loginIp }})</span>
							</div>
							<div class="textInfo">
								<span class="info">上次登录：</span>
								<span>{{ userObj.loginTimePrevious }}</span>
							</div>
						</div>
					</div>
					<div class="flex" slot="reference" style="width: 90px;">
						<el-avatar size="large" :src="this.src" fit="cover" style="width: 30px;height: 30px;margin-top: 5px;"></el-avatar>
						<div class="admin ml-5">{{ userObj.name }}</div>
					</div>
				</el-popover>
				<div style="padding: 3px;cursor: pointer;" @click="alarmNoise" v-if="role == 'company'">
					<img src="@assets/osImg/home/alarmNoise.png" style="height: 20px;vertical-align: top;" v-if="isAlarmNoise" />
					<img src="@assets/osImg/home/noneNoise.png" style="height: 20px;vertical-align: top;" v-else />
					<span style="font-size: 14px;vertical-align: middle;">报警</span>
				</div>
				<div class="switch">
					<el-dropdown @command="handleCommand">
						<i class="el-icon-switch-button"></i>
						<el-dropdown-menu slot="dropdown"><el-dropdown-item command="out">退出登录</el-dropdown-item></el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</el-header>
		<el-container style="height: 90%;">
			<transition name="el-fade-in-linear">
				<aside-menu ref="asideMenu" :list="sideMenu" v-if="sideMenu.length > 0" @handleSelect="selectAsideMenu" style="min-width:200px"></aside-menu>
			</transition>

			<el-container style="display: flex;flex-direction: column;">
				<div style="overflow: auto;flex: 1;"><router-view v-if="isRouterAlive" style="height: 100%;" /></div>
			</el-container>
		</el-container>

		<platform-edit :isShow.sync="showPlatEdit" :dialogObj="platEditObj"></platform-edit>
		<company-edit :isShow.sync="showCompanyEdit" :dialogObj="companyEditObj"></company-edit>
	</el-container>
</template>

<script>
import caches from '@cache/cache.js';
import asideMenu from '@c/asideMenu.vue';
import store from '../../store/index';
import configApi from '../../config/configApi';
// import smFooter from '@c/smFooter.vue';
// import pageHeader from '@c/pageHeader.vue';

import platformEdit from './accSetting/platform/edit.vue';
import companyEdit from './accSetting/company/edit.vue';
export default {
	components: {
		asideMenu,
		// smFooter,
		// pageHeader,
		platformEdit,
		companyEdit
	},
	provide() {
		return {
			reload: this.reload
		};
	},
	data() {
		return {
			role: '',
			userRole: '',
			activeTopMenu: {},
			isRouterAlive: true,
			userObj: {
				dept:'',
				loginTimeCurrent:'',
				loginArea:'',
				loginIp:'',
				loginTimePrevious:'',
				name:''
			},
			src: '',
			topMenu: [],
			sideMenu: [],
			showPlatEdit: false,
			showCompanyEdit: false,
			platEditObj: {},
			companyEditObj: {},
			audio: '',
			alarmInfoInterval: null,
			isAlarmNoise: false,
			options: [
				{
					value: '1',
					label: ''
				}
			],
			topMenuIndex: null
		};
	},
	watch: {
		'$route.name': {
			handler(val, oldVal) {
				switch (val) {
					case caches.role.get():
						//平台方和企业方登录后跳转到监控中心
						if (val == 'platform' && oldVal == 'home') {
							$('.topMenu .el-menu-item')
								.eq(0)
								.trigger('click');
						} else if (val == 'expertOrder' && oldVal == 'home') {
							$('.topMenu .el-menu-item')
								.eq(0)
								.trigger('click');
						} else {
							caches.currentTopMenu.remove();
							this.activeTopMenu = {};
							this.sideMenu = [];
						}
						break;
					case 'home':
						this.$router.replace(caches.role.get());
						caches.currentTopMenu.remove();
						this.activeTopMenu = {};
						this.sideMenu = [];
						break;
					default:
						if (caches.currentTopMenu.get()) {
							this.sideMenu = caches.currentTopMenu.get().child ? caches.currentTopMenu.get().child : [];
							var sideMenus = [];
							//生成菜单列表
							for (var i in this.sideMenu) {
								var item = this.sideMenu[i].child;
								for (var j in item) {
									if (item[j].child) {
										for (var k in item[j].child) {
											sideMenus.push(item[j].child[k]);
										}
									} else {
										sideMenus.push(item[j]);
									}
								}
							}
							for (var i in sideMenus) {
								if (sideMenus[i].url == val) {
									var index = i;
									this.$nextTick(() => {
										if (this.$refs.asideMenu) {
											this.$refs.asideMenu.resetDefaultActive(index);
										}
									});
								} else {
									if (this.$refs.asideMenu) {
										this.$refs.asideMenu.resetDefaultActive2();
									}
								}
							}
						} else {
							//登录后执行这一段
							// if (val == 'companyversion' && caches.role.get() == 'company') {
							// 	this.getlist()
							// }
							// if (val == 'expertZdOrder' && caches.role.get() == 'expert') {
							// 	this.getlist()
							// }
							this.getlist();
						}
				}
				caches.currentUrl.set(val);
			},
			immediate: true
		}
	},
	mounted() {
		this.getUser();
		this.getMenu();
		// 检测是否能自动播放
		this.initAutoPlay();
		// 用户点击交互之后，设置成能自动播放
		this.setAutoPlayWhenClick();
		//暂停
		// mp3.pause();
		// mp3.load();
		this.Bus.$off('clickDanti').$on('clickDanti', param => {
			let type = undefined;
			this.topMenu.forEach((item, i) => {
				if (item.id == '004') {
					type = i;
					caches.currentTopMenu.set(this.topMenu[type]);
					this.setTopMenuSelected(type);
					this.sideMenu = [];
					this.$router.replace({
						name: 'companydiagnosis',
						params: param
					});
				}
			});
			if (type == undefined) {
				this.$message.warning('您暂无权限，请联系管理员');
			}
			this.topMenuIndex = null;
		});
		history.pushState(null, null, document.URL);
		
		window.addEventListener("popstate", function(e) {
		
			history.pushState(null, null, document.URL);
		
		}, false);
	},
	methods: {
		geneRole(userType) {
			if (userType == 1) {
				this.role = 'platform';
			} else if (userType == 2) {
				this.role = 'company';
			} else if (userType == 3) {
				this.role = 'supplier';
			} else if (userType == 4) {
				this.role = 'expert';
			}
			caches.role.set(this.role);
		},
		getlist() {
			this.$get('user-api/user-base/menu/list').then(res => {
				let arr = [];
				this.getChildren(res.data, arr);
				let type = 0;
				res.data.forEach((item, i) => {
					//跳转到指定页面，通过id来获取顶级菜单来判断，目前应用在企业端跳到3D可视化页面
					if (item.id == '005') {
						type = i;
						this.sideMenu = item.child;
						$('.topMenu .el-menu-item')
							.eq(i)
							.trigger('click');
					}
				});
				if (type == 0) {
					this.sideMenu = res.data[type].child;
					$('.topMenu .el-menu-item')
						.eq(type)
						.trigger('click');
				}
			});
		},
		removeSelectedTopMenu() {
			this.$nextTick(() => {
				$('.topMenu .el-menu-item').removeClass('is-active');
			});
		},
		setTopMenuSelected(i) {
			this.$nextTick(() => {
				$('.topMenu .el-menu-item').removeClass('is-active');
				$('.topMenu .el-menu-item')
					.eq(i)
					.addClass('is-active');
			});
		},
		//点击顶部菜单
		selectTopMenu(item, index) {
			if (this.topMenuIndex == index) return; //点击当前tab栏拦截
			this.topMenuIndex = index;
			item.index = index;
			caches.currentTopMenu.set(item);
			this.activeTopMenu = item;
			this.setTopMenuSelected(index);
			this.$router.replace({
				name: item.url
			});
		},
		//点击左侧菜单
		selectAsideMenu(item) {
			if (item.url) {
				this.$router.replace({
					name: item.url
				});
			}
		},
		openIndex() {
			if (this.role == 'company') return;
			this.$router.replace({
				name: this.role
			});
		},
		// 获取用户信息
		getUser() {
			if (caches.user.get()) {
				var user = caches.user.get();
				this.options[0].label = user.dept;
				this.userObj = user;
				if (this.userObj) {
					this.src = this.photoURL + this.userObj.head;
					this.userRole = this.userObj.userType;
					this.geneRole(this.userObj.userType);
				}
			} else {
				this.$get('user-api/user-base/userInfo', {}).then(res => {
					caches.user.set(res.data);
					this.userObj = res.data;
					if (this.userObj) {
						this.src = this.photoURL + this.userObj.head;
						this.userRole = this.userObj.userType;
						this.geneRole(this.userObj.userType);
					}
				});
			}
		},
		//获取菜单信息
		getMenu() {
			if (caches.topMenu.get()) {
				this.topMenu = caches.topMenu.get();
				if (caches.currentTopMenu.get()) {
					this.activeTopMenu = caches.currentTopMenu.get();
					this.setTopMenuSelected(caches.currentTopMenu.get().index);
				} else {
					this.removeSelectedTopMenu();
				}
			} else {
				this.$get('user-api/user-base/menu/list', {}).then(res => {
					let arr = [];
					this.getChildren(res.data, arr);
					this.topMenu = res.data;
					caches.topMenu.set(this.topMenu);
					caches.codes.set(arr);
					if (caches.currentTopMenu.get()) {
						this.activeTopMenu = caches.currentTopMenu.get();
						this.setTopMenuSelected(caches.currentTopMenu.get().index);
					} else {
						this.removeSelectedTopMenu();
					}
				});
			}
		},
		getChildren(obj, arr) {
			let flag = false;
			for (let item of obj) {
				if (item.level == 9) {
					arr.push(item.code);
					flag = true;
				}
				if (item.child && item.child.length > 0) {
					if (this.getChildren(item.child, arr)) {
						item.child = null;
					}
				}
			}
			return flag;
		},
		//打开账户设置
		accSetting() {
			if (this.role == 'platform') {
				this.showPlatEdit = true;
			} else if (this.role == 'company') {
				this.showCompanyEdit = true;
			}
		},
		handleCommand(val) {
			switch (val) {
				case 'out':
					this.$get('user-api/auth/logout', {})
						.then(res => {
							caches.clearAll();
							this.$router.replace('/index');
							this.$message({
								message: '登出成功',
								type: 'success'
							});
						})
						.catch(err => {
							this.$message.error(err);
						});
					break;
			}
		},
		//刷新router
		reload() {
			this.isRouterAlive = false;
			this.$nextTick(() => {
				this.isRouterAlive = true;
			});
		},
		initAutoPlay() {
			// 返回一个promise以告诉调用者检测结果
			this.audio = document.createElement('audio');
			// require一个本地文件，会变成base64格式
			this.audio.src = require('@/assets/video/alarm.mp3');
			document.body.appendChild(this.audio);
		},
		// 监听页面的点击事件，一旦点过了就能autoplay了
		setAutoPlayWhenClick() {
			let _this = this;

			function setAutoPlay() {
				// 设置自动播放
				_this.getAllEquipment();
				_this.alarmInfoInterval = setInterval(_this.getAllEquipment, 7000);
				document.removeEventListener('click', setAutoPlay);
				document.removeEventListener('touchend', setAutoPlay);
			}
			document.addEventListener('click', setAutoPlay);
			document.addEventListener('touchend', setAutoPlay);
		},
		autoPlayAudio() {
			this.audio.play();
			$(this.audio).bind('ended', () => {
				this.audio.play();
			});
		},
		stopPlayAudio() {
			this.audio.pause();
			$(this.audio).unbind('ended');
		},
		getAllEquipment() {
			if (this.isAlarmNoise) {
				this.$get('/backend-api/web/monitor/alarm/ent', null).then(res => {
					if (res.code == 1000) {
						if (res.data[1].value == 0 && res.data[2].value == 0 && res.data[3].value == 0) {
							this.stopPlayAudio();
						} else {
							this.stopPlayAudio();
							this.autoPlayAudio();
						}
					} else {
						// this.$message.error(res.msg);
					}
				});
			}
		},
		alarmNoise() {
			let title = '';
			let text = '';
			if (this.isAlarmNoise) {
				title = '关闭设备报警';
				text = '确定关闭设备报警声, 设备异常将无报警声!';
			} else {
				title = '开启设备报警声音';
				text = '确定开启报警声, 设备异常将出现报警声!';
			}
			this.$confirm(text, title, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.isAlarmNoise = !this.isAlarmNoise;
				if (!this.isAlarmNoise) {
					this.stopPlayAudio();
				}
			});
		}
	},
	beforeDestroy() {
		clearInterval(this.alarmInfoInterval);
		this.stopPlayAudio();
	}
};
</script>

<style lang="scss">
@import '../../style/home.scss';

.indexTopBox {
	width: 100%;
	background-color: #fff;

	.indexTop {
		width: 1420px;
		height: 60px;
		margin: 0 auto;
	}
}

.home {
	.hover {
		cursor: pointer;
	}

	.el-avatar > img {
		width: 100%;
	}

	background-color: #f5f5f5;
	height: 100vh;

	.main {
		width: 1420px;
		margin: 0 auto;
	}
}

.el-popover {
	.userInfo {
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid #f5f5f5;
	}

	.textInfo {
		height: 30px;
		line-height: 30px;
	}

	.info {
		color: #9b9b9b;
	}
}
</style>
