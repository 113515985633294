<template>
	<el-menu class="el-menu-vertical-demo asideMenu" background-color="#262f39" style="width: 200px;"
		:default-openeds="['item0','item1','item2']">
		<el-submenu v-for="(item, i) in list" class="outerMenu" :index="'item'+i">
			<span slot="title" class="outerMenu_span" @click="handleSelect(item)">{{item.name}}</span>
			<template v-for="(subItem, i_) in item.child">
				<el-submenu :index="subItem.name" v-if="subItem.child">
					<span slot="title">{{subItem.name}}</span>
					<el-menu-item :index="subItem_.url" class="menu_item subMenu-item"
						v-for="(subItem_, i__) in subItem.child" @click="handleSelect(subItem_)"
						style="padding-left: 60px !important;">
						{{subItem_.name}}
						<i v-if='subItem.hover==1' class="el-icon-arrow-right"
							style="position: absolute;right: 0;top: 10px;transform:scale(0.7);"></i>
					</el-menu-item>
				</el-submenu>
				<el-menu-item :index="subItem.url" v-else class="menu_item" @click="handleSelect(subItem)">
					{{subItem.name}}
					<div style="position: absolute;right: 0;top: 0px;" @mouseenter="enter(subItem)"
						@mouseleave="leave()">
						<i v-if='subItem.hover==1' class="el-icon-arrow-right" style="transform:scale(0.7);"></i>
					</div>
				</el-menu-item>
			</template>
		</el-submenu>
	</el-menu>
	<!-- <el-aside width="200px" style="background-color: #262f39">
		<el-menu class="el-menu-vertical-demo asideMenu" background-color="#262f39" style="width: 200px;">
			<el-submenu v-for="(item, i) in list" :key="i" class="outerMenu" :index="'item'+i">
				<template slot="title"><i class="el-icon-message"></i>{{item.name}}</template>
				<el-submenu v-for="(subItem, i_) in item.child" :key="i_" :index="'subItem'+i_">
					<template slot="title" class="menu_item" @click="handleSelect(subItem)">{{subItem.name}}</template>
					<el-menu-item-group v-for="(subItem_, i__) in subItem.child" :key="i__" >
					  <el-menu-item class="menu_item subMenu-item">{{subItem_.name}}</el-menu-item>
					</el-menu-item-group>
				</el-submenu>
			</el-submenu>
		</el-menu>
	</el-aside> -->
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
				default () {
					return [];
				}
			}
		},
		methods: {
			handleSelect(val) {
				this.$emit("handleSelect", val);
			},
			enter(e) {
				// console.log('鼠标移入')
				if (e.hover != 1) return
				this.Bus.$emit("asideChange", {
					param: 1
				})
			},
			leave() {
				// console.log('鼠标移出')
			},
			resetDefaultActive(i) {
				$(".asideMenu .el-menu-item").removeClass("is-active");
				$(".asideMenu .el-menu-item").eq(i).addClass("is-active");
			},
			resetDefaultActive2() {
				$(".asideMenu .el-menu-item").removeClass("is-active");
			}
		},
	}
</script>

<style lang="scss">
	.menu_item {
		padding-left: 40px !important;
		font-size: 12px;
		height: 40px !important;
		line-height: 40px !important;
		color: #ACACAC;
		position: relative;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.menu_item.is-active {
		background-color: #2388FF !important;
		color: #FFFFFF;
	}

	.el-submenu__title {
		line-height: 40px;
		height: 40px;
		font-size: 12px;
		padding-left: 40px !important;
		color: #FFFFFF;
	}

	.outerMenu.el-menu-item-group .el-menu-item-group__title {
		background-color: #3c444d;
		padding: 0 !important;
		font-size: 16px;
		font-weight: bold;
		color: #DBDBDB;
	}

	.outerMenu_span {
		// padding-left: 40px;
		font-size: 14px;
		line-height: 50px;
		height: 50px;
		display: inline-block
	}
</style>