<template>
	<el-dialog class="dialog" title="账户设置" :visible.sync="visiable" v-if="visiable" width="45%">
		<div v-loading="loading" class="dlalog-body">
			<div class="centerInfo">
				<div class="centerInfo-item">
					<div class="title">账户资料</div>
					<div class="content">
						<el-form ref="form" :inline="true" :model="formData" :rules="rules" label-width="120px">
							<el-row style="margin-bottom: 24px;">
								<el-col :span="24">
									<el-upload class="avatar-uploader" action="" :show-file-list="false"
										:before-upload="BeforeUpload" :http-request="Upload">
										<el-image style="width: 80px; height: 80px" v-if="headUrl" :src="headUrl"
											fit="fill">
										</el-image>
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									</el-upload>
									<div class="header-text">上传头像</div>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="所属部门：">
										<el-input v-model="formData.deptName" disabled></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="用户账号：">
										<el-input v-model="formData.accountNo" disabled></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="账号权限：">
										<el-input v-model="roles" disabled></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="姓名：">
										<el-input v-model="formData.name"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="邮箱：" prop="email">
										<el-input v-model="formData.email"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="手机号码：" prop="mobile">
										<el-input v-model="formData.mobile" disabled></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="性别：">
										<el-select v-model="formData.sex">
											<el-option :value="0" label="保密"> </el-option>
											<el-option :value="1" label="男"> </el-option>
											<el-option :value="2" label="女"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24" style="text-align: right;">
									<el-button type="text" @click="updateMobile">修改手机号</el-button>
									<el-button type="text" @click="updatePwd">修改密码</el-button>
								</el-col>
							</el-row>
						</el-form>
					</div>
				</div>
				<div class="centerInfo-item" style="margin-left: 12px;"></div>
				<!-- <div class="centerInfo-item" style="margin-left: 12px;">
					<div class="title">企业资料</div>
					<div class="content">
						<el-form ref="form" :inline="true" :model="formData" label-width="120px">
							<el-row style="margin-bottom: 24px;">
								<el-col :span="24">
									<el-upload class="avatar-uploader" action="" :show-file-list="false"
										:before-upload="BeforeUpload" :http-request="Upload">
										<el-image style="width: 80px; height: 80px" v-if="headUrl" :src="headUrl"
											fit="fill">
										</el-image>
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									</el-upload>
									<div class="header-text">上传头像</div>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="企业名称：">
										<el-input v-model="formData.name"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="联系人：">
										<el-input v-model="formData.name"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="联系电话：">
										<el-input v-model="formData.name"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="联系邮箱：">
										<el-input v-model="formData.name"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="企业地址：">
										<el-input v-model="formData.name"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="24">
									<el-form-item label="备注：">
										<el-input type="textarea" v-model="formData.name"></el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
					</div>
				</div> -->
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
		<update-pwd :isShow.sync="showUpdatePwdDialog" :dialogObj="updatePwdObj"></update-pwd>
		<update-mobile :isShow.sync="showUpdateMobileDialog" :dialogObj="updateMobileObj"></update-mobile>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	import updatePwd from './updatePwd.vue'
	import updateMobile from './updateMobile.vue'
	export default {
		mixins: [dialogMixin],
		components: {
			updatePwd,
			updateMobile
		},
		data() {
			return {
				loading: false,
				formData: {
					sex: 0
				},
				roles: "",
				newFile: new FormData(),
				headUrl: "",
				showUpdatePwdDialog: false,
				showUpdateMobileDialog: false,
				updatePwdObj: {},
				updateMobileObj: {},
				title: "",
				rules: {
					mobile: {
						validator: (rule, value, callback) => {
							const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
							if (!value) {
								return callback(new Error('电话号码不能为空'))
							}
							setTimeout(() => {
								if (!Number.isInteger(+value)) {
									callback(new Error('请输入数字'))
								} else {
									if (phoneReg.test(value)) {
										callback()
									} else {
										callback(new Error('电话号码格式不正确'))
									}
								}
							}, 100)
						},
						trigger: 'blur'
					},
					email: {
						validator: (rule, value, callback) => {
							const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
							if (!value) {
								return callback(new Error('邮箱不能为空'))
							}
							setTimeout(() => {
								if (mailReg.test(value)) {
									callback()
								} else {
									callback(new Error('请输入正确的邮箱格式'))
								}
							}, 100)
						},
						trigger: 'blur'
					},
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {
					sex: 0
				};
				this.headUrl = "";
				this.roles = "";
				this.get();
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
			},
			//获取数据
			get() {
				this.$get("/user-api/ent/user/my-get", null).then((res) => {
					if (res.code == 1000) {
						this.formData = res.data
						//账号权限回显
						for (let i in this.formData.roles) {
							var item = this.formData.roles[i];
							this.roles += item.name;
							if (i < this.formData.roles.length - 1) {
								this.roles += ",";
							}
						}
						//头像
						if (this.formData.head) {
							this.headUrl = configApi.photoURL + this.formData.head
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开修改密码弹窗
			updatePwd() {
				this.showUpdatePwdDialog = true;
			},
			//打开修改手机号弹窗
			updateMobile() {
				this.updateMobileObj={
					mobile:this.formData.mobile
				}
				this.showUpdateMobileDialog = true;
			},
			//上传前校验
			BeforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 10;
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
					this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
				} else if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 10MB!');
				} else {
					if (file) {
						if(this.newFile.has("file")){
							this.newFile.delete("file");
						}
						this.newFile.append('file', file);
						return true;
					} else {
						return false;
					}
				}
				return false;
			},
			//手动上传
			Upload() {
				this.loading = true;
				this.$upload(this.newFile).then((res) => {
					if (res.code == 1000) {
						this.formData.head = res.data
						this.headUrl = configApi.photoURL + this.formData.head
						this.loading = false;
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				}).catch(error => {
					this.$message.error(error);
				});
			},
			//提交表单
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData("/user-api/ent/user/my-save", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss";

	.centerInfo {
		display: flex;
		width: 100%;
		padding: 24px 40px;

		.centerInfo-item {
			flex: 1;
			background: #FFFFFF;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
			border-radius: 4px;

			.title {
				height: 40px;
				border-bottom: #F0F0F0 1px solid;
				font-size: 14px;
				font-weight: 500;
				color: #595959;
				line-height: 40px;
				text-align: center;
			}

			.content {
				::v-deep .el-form-item__content {
					flex: 1;
				}

				.header-text {
					text-align: center;
					font-size: 12px;
					font-weight: 400;
					color: #8C8C8C;
					line-height: 20px;
					margin-top: 10px;
				}
			}
		}
	}

	.avatar-uploader {
		text-align: center;

		::v-deep .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 80px;
			height: 80px;
		}

		::v-deep .el-upload:hover {
			border-color: #409EFF;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
</style>
