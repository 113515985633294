<template>
	<el-dialog class="dialog" title="修改密码" :visible.sync="visiable" append-to-body width="30%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form2" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="旧密码：" prop="oldPwd">
							<el-input v-model="formData.oldPwd"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="新密码：" prop="newPwd">
							<el-input v-model="formData.newPwd"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="确认新密码：" prop="newPwd2">
							<el-input v-model="formData.newPwd2"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
				rules: {
					oldPwd: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					newPwd: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					newPwd2: [{
						validator: (rule, value, callback) => {
							if (!value) {
								return callback(new Error('此项必填'))
							}else if (value != this.formData.newPwd) {
								return callback(new Error('两次输入的密码不一致'))
							}else{
								callback();
							}
						},
						trigger: 'blur'
					}],
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.$nextTick(() => {
					this.$refs.form2.clearValidate();
				})
			},
			//提交表单
			submit() {
				this.$refs["form2"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$post("/user-api/user-base/password/update", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});

			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
