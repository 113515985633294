<template>
	<el-dialog class="dialog" title="修改手机号" :visible.sync="visiable" v-if="visiable" append-to-body width="25%">
		<div v-loading="loading" class="dlalog-body">
			<el-steps :active="active" align-center style="padding: 10px;">
				<el-step title="验证身份"></el-step>
				<el-step title="修改手机号码"></el-step>
				<el-step title="完成更换"></el-step>
			</el-steps>
			<el-form ref="form1" :rules="rules" :model="formData1" label-width="120px" v-if="active==0">
				<div style="padding: 10px 60px 0;">已绑定的手机号：<span style="color: #4A99F6;">{{dialogObj.mobile}}</span>
				</div>
				<div style="color: #8C8C8C;padding: 10px 60px;">若该手机号已无法使用请联系客服</div>
				<el-row>
					<el-col :span="24">
						<el-form-item class="sms" label="验证码:" prop="smsCode" style="width: 85%;">
							<el-input placeholder="短信验证码" v-model="formData1.smsCode" style="flex:1">
								<i slot="prefix" class="el-icon-chat-dot-round"></i>
							</el-input>
							<el-button @click="getSms1()" style="width: 110px;margin-left: 10px;">{{smsText}}</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-form ref="form2" :rules="rules" :model="formData2" label-width="120px" v-if="active==1">
				<el-row>
					<el-col :span="24">
						<el-form-item class="sms" label="手机号:" prop="mobile" style="width: 85%;">
							<el-input placeholder="请输入新的手机号码" v-model="formData2.mobile" style="flex:1">
								<i slot="prefix" class="el-icon-chat-dot-round"></i>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item class="sms" label="验证码:" prop="smsCode" style="width: 85%;">
							<el-input placeholder="短信验证码" v-model="formData2.smsCode" style="flex:1">
								<i slot="prefix" class="el-icon-chat-dot-round"></i>
							</el-input>
							<el-button @click="getSms2()" style="width: 110px;margin-left: 10px;">{{smsText2}}</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div v-if="active==2" style="text-align: center;margin-bottom: 40px;">
				<img src="@assets/osImg/accSetting/success.png" style="width: 90px;height: 90px;" />
				<div>恭喜提交资料成功</div>
				<div>我们将在1-3个工作日内完成审核</div>
			</div>
			<div style="padding: 0 130px 0 100px;position: relative;top: -20px;">
				<el-button type="primary" style="width: 100%; " v-if="active<2"
					@click="next">下一步
				</el-button>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				active: 0,
				title: "",
				loading: false,
				times: 60,
				times1: 60,
				formData1: {},
				formData2: {},
				timer1: null,
				timer2: null,
				smsText:'获取验证码',
				smsText2:'获取验证码',
				rules: {
					smsCode: {
						required: true,
						message: "此项必填",
						trigger: 'blur'
					},
					mobile: {
						validator: (rule, value, callback) => {
							const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
							if (!value) {
								return callback(new Error('电话号码不能为空'))
							}
							setTimeout(() => {
								if (!Number.isInteger(+value)) {
									callback(new Error('请输入数字'))
								} else {
									if (phoneReg.test(value)) {
										callback()
									} else {
										callback(new Error('电话号码格式不正确'))
									}
								}
							}, 100)
						},
						trigger: 'blur'
					}
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.$nextTick(() => {
					if (this.active == 0) {
						this.$refs.form1.clearValidate();
					} else {
						this.$refs.form2.clearValidate();
					}
				})
			},
			next() {
				if (this.active == 0) {
					this.submit1();
				} else {
					this.submit2();
				}
			},
			getSms1() {
				let _this = this;
				if (this.times != 60) {
					this.$message('请稍后再试')
					return
				}
				this.$post('/user-api/user-base/mobile/old/send', null).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg)
						return
					}
					this.$message({
						message: "发送成功",
						type: "success",
					});
					this.timer1 = setInterval(function() {
						_this.smsText = _this.times + 's后重试'
						if (_this.times == 0) {
							_this.times = 60;
							clearInterval(_this.timer1);
							_this.smsText = '获取验证码'
						} else {
							_this.times--
						}
					}, 1000) //每隔1000毫秒弹一次
				})
			},
			getSms2() {
				let _this = this;
				if (this.times1 != 60) {
					this.$message('请稍后再试')
					return
				}
				this.$post('/user-api/user-base/mobile/new/send', {
					mobile: this.formData2.mobile
				}).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg)
						return
					}
					this.$message({
						message: "发送成功",
						type: "success",
					});
					this.timer2 = setInterval(function() {
						_this.smsText2 = _this.times1 + 's后重试'
						if (_this.times1 == 0) {
							_this.times1 = 60;
							clearInterval(_this.timer2);
							_this.smsText2 = '获取验证码'
						} else {
							_this.times1--
						}
					}, 1000) //每隔1000毫秒弹一次
				})
			},
			submit1() {
				let _this = this;
				this.$refs["form1"].validate((valid) => {
					if (valid) {
						_this.loading = true;
						_this.$post("/user-api/user-base/mobile/old/valid", _this.formData1).then((res) => {
							if (res.code == 1000) {
								_this.loading = false;
								_this.formData2.key = res.data.key;
								_this.$message.success(res.msg);
								_this.active++;
								_this.times = 60;
								clearInterval(_this.timer1);
							} else {
								_this.loading = false;
								_this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
			submit2() {
				let _this = this;
				this.$refs["form2"].validate((valid) => {
					if (valid) {
						_this.loading = true;
						_this.$post("/user-api/user-base/mobile/new/valid", _this.formData2).then((res) => {
							if (res.code == 1000) {
								_this.loading = false;
								_this.$message.success(res.msg);
								_this.active++;
							} else {
								_this.active = 0;
								_this.loading = false;
								_this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				// this.visiable = false;
			}
		},
		destroyed() {
			this.close();
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.el-dialog {
			min-width: 500px;

		}

		::v-deep.cascader {
			display: block;
		}

		.sms {
			::v-deep.el-form-item__content {
				display: flex;
			}
		}
	}
</style>
